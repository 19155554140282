@font-face {
    font-family: 'Velino';
    src: url('/ui/assets/fonts/Velino/VelinoCompressedText-Medium.eot');
    src: url('/ui/assets/fonts/Velino/VelinoCompressedText-Medium.eot?#iefix') format('embedded-opentype'),
         url('/ui/assets/fonts/Velino/VelinoCompressedText-Medium.svg#VelinoCompressedText-Medium') format('svg'),
         url('/ui/assets/fonts/Velino/VelinoCompressedText-Medium.ttf') format('truetype'),
         url('/ui/assets/fonts/Velino/VelinoCompressedText-Medium.woff') format('woff'),
         url('/ui/assets/fonts/Velino/VelinoCompressedText-Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
@font-face {
  font-family: 'Velino';
  src: url('/ui/assets/fonts/Velino/VelinoCondensedText-Black.eot');
  src: url('/ui/assets/fonts/Velino/VelinoCondensedText-Black.eot?#iefix') format('embedded-opentype'),
       url('/ui/assets/fonts/Velino/VelinoCondensedText-Black.svg#VelinoCondensedText-Black') format('svg'),
       url('/ui/assets/fonts/Velino/VelinoCondensedText-Black.ttf') format('truetype'),
       url('/ui/assets/fonts/Velino/VelinoCondensedText-Black.woff') format('woff'),
       url('/ui/assets/fonts/Velino/VelinoCondensedText-Black.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
